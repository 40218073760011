$pwdstr-neutral-color: color('neutral-500');
$pwdstr-not-met-color: color('red-500');
$pwdstr-weak-color: #f5bd03;
$pwdstr-good-color: color('green-400');
$pwdstr-strong-color: color('green-400');

.pw-strength {
  display: block;
  width: 100%;
  &__bar {
    background-color: color('neutral-200');
    border-radius: 5px;
    display: block;
    flex: 1 0 auto;
    height: 100%;
    margin-right: 10px;
    transition: background-color 0.3s;
    &:last-child {
      margin-right: 0;
    }
  }
  &__desc {
    display: block;
    margin-bottom: 15px;
  }
  &__meter {
    border-radius: 5px;
    display: flex;
    height: 10px;
    margin-bottom: 5px;
    position: relative;
    width: 100%;
  }
  &__score {
    font-weight: bold;
  }
  &[data-pwstrength="EMPTY"] {
    .pw-strength__score {
      color: $pwdstr-neutral-color;
    }
  }
  &[data-pwstrength="NOT_MET"] {
    [data-pwstrength-category="NOT_MET"] {
      background-color: $pwdstr-not-met-color;
    }
    .pw-strength__score {
      color: $pwdstr-not-met-color;
    }
  }
  &[data-pwstrength="WEAK"] {
    [data-pwstrength-category="NOT_MET"],
    [data-pwstrength-category="WEAK"] {
      background-color: $pwdstr-weak-color;
    }
    .pw-strength__label {
      color: color('neutral-900');
    }
    .pw-strength__score {
      color: $pwdstr-weak-color;
    }
  }
  &[data-pwstrength="GOOD"] {
    [data-pwstrength-category="NOT_MET"],
    [data-pwstrength-category="WEAK"],
    [data-pwstrength-category="GOOD"] {
      background-color: $pwdstr-good-color;
    }
    .pw-strength__label {
      color: color('neutral-900');
    }
    .pw-strength__score {
      color: $pwdstr-good-color;
    }
  }
  &[data-pwstrength="STRONG"] {
    .pw-strength__bar {
      background-color: $pwdstr-strong-color;
    }
    .pw-strength__label {
      color: color('neutral-900');
    }
    .pw-strength__score {
      color: $pwdstr-strong-color;
    }
  }
}
